import * as React from "react"
import Layout from "../components/layout.js"
import Image from "../components/image"

const OldMemoriesPage = () => {
  return (
    <Layout title="Self-Immolation">
        <h2>Scenario</h2>
        <blockquote>If I ever had a soul, I burnt it long ago at some nameless altar.<br /><br />
        Did smoke rise up?<br />
        Did it smell like sandalwood and sage?<br />
        <small>"<a href="https://chapbook.strangesatellite.net/nameless-altar">The Nameless Altar</a>"</small></blockquote>
        <center><Image src="selfimmolation.png" alt="Drawing" /></center>
    </Layout>
  )
}

export default OldMemoriesPage